.player-list__player-list,
.player-list__team-list {
  padding: 0;
  width: 100%;
}

.player-list__player-list-item {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.player-list__player-avatar {
  margin-right: 8px;
}

.player-list__player-name {
  flex: 1;
}

.player-list__player-list-item--self {
  color: var(--color-primary);
}

.player-list__team-header {
  display: flex;
  flex-direction: row;
}

.player-list__team-name {
  flex: 1;
}

.player-list__join-team-btn {
  border: none;
  text-transform: uppercase;
  background: none;
  cursor: pointer;
  transition: var(--default-transition);
  color: var(--text-color-hint);
}

.player-list__join-team-btn:hover {
  color: var(--text-color-default);
}
