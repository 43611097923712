.home {
  padding: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.home__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  width: 100%;
}

.home__join-game {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.home__game-code-input {
  margin-right: 8px;
}
