.color-picker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.color-picker__color {
  width: 24px;
  height: 24px;
  margin: 2px;
  border: none;
  cursor: pointer;
  border-radius: 100%;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);
  transition: var(--default-transition);
}

.color-picker__color:hover,
.color-picker__color--active {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transform: translate(1px, 1px);
}

.color-picker__check {
  padding: 2px;
  opacity: 0.8;
}
