.player-editor {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 64px;
}

.player-editor__card {
  display: flex;
  flex-direction: column;
  width: 340px;
}

.player-editor__actions {
  display: flex;
  flex-direction: row;
}

.player-editor__action-btn {
  flex: 1;
  margin: 4px;
}

.player-editor__color-picker {
  margin-bottom: 8px;
}
