.notification-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  pointer-events: none;
}

.notification {
  min-width: 300px;
  padding: 8px 16px;
  background: #333;
  color: white;

  &:last-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
}
