.footer {
  display: flex;
  flex-direction: row;
  z-index: 0;
  padding: 8px;
  grid-gap: 8px;
}

.background-selector {
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
}

.background-selector__background {
  border: 2px solid var(--button-default);
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background: none;
  background-size: cover;
  cursor: pointer;
}

.theme-selector {
  padding: 2px;
  color: var(--footer-color);
}

.links {
  text-decoration: none;
  color: var(--footer-color);
  padding: 0 8px;
}
