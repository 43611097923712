@import url('https://fonts.googleapis.com/css2?family=Baloo+Paaji+2&display=swap');

* {
  font-family: 'Baloo Paaji 2', sans-serif;
}

.title {
  margin: 16px 0;
  font-size: 36px;
  line-height: 1;
}

.subtitle {
  margin: 8px 0;
  font-size: 24px;
  line-height: 1;
  opacity: 0.7;
}
