.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--card-background);
  box-shadow: var(--default-shadow);
}

.header__title {
  margin-left: 16px;
  font-size: 16px;
}

.header__title-link {
  text-decoration: none;
  flex: 1;
  padding: 4px;
}
.header__title-link:hover {
  text-decoration: underline;
}

.header__change-name,
.header__player-name {
  padding: 4px;
  font-size: 14px;
}

.header__player-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header__player-settings {
  display: flex;
  padding: 8px;
  grid-gap: 8px;
  flex-direction: row;
  align-items: center;
  opacity: 1;
  border: none;
  background: none;
  cursor: pointer;
  transition: var(--default-transition);
}

.header__player-settings:hover {
  background: rgba(0, 0, 0, 0.1);
}

.header__player-img {
  width: 48px;
  height: 48px;
}
